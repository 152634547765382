body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-lg, .btn-group-lg > a > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > a > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-group > a > .btn,
.btn-group-vertical > a > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > a > .btn:hover,
.btn-group-vertical > a > .btn:hover {
  z-index: 1;
}

.btn-group > a > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > a > .btn:focus,
.btn-group-vertical > a > .btn:active,
.btn-group-vertical > a > .btn.active {
  z-index: 1;
}

.btn-group > a:not(:first-child) > .btn,
.btn-group > a:not(:first-child) > .btn-group {
  margin-left: -1px;
}

.btn-group > a:not(:last-child) > .btn:not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > a > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > a:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > a > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}