.mentions-deletion{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  padding: 70px;
}
.mentions-deletion h1{
  font-size: 30px;
  text-align: center;
}
.mentions-deletion h3{
  font-size: 20px;
}