.form-control.form-control-error {
    border-color: #dc3545;
}

.input-group .input-group-text.input-group-text-error {
    border-color:#dc3545;
    color: #dc3545;
}

.invalid-feedback {
    margin-top: -15px;
    margin-bottom: 15px;
    padding-left: 5px;
}

.invalid-feedback.visible {
    display: block;
}

.input-group .form-control,
.register-card-body .input-group .form-control {
  border-right: 0;
}

.input-group .form-control:focus,
.register-card-body .input-group .form-control:focus {
  box-shadow: none;
}

.input-group .form-control:focus ~ .input-group-append .input-group-text,
.register-card-body .input-group .form-control:focus ~ .input-group-append .input-group-text {
  border-color: #80bdff;
}

.input-group .form-control.is-valid:focus,
.register-card-body .input-group .form-control.is-valid:focus {
  box-shadow: none;
}

.input-group .form-control.is-valid ~ .input-group-append .input-group-text,
.register-card-body .input-group .form-control.is-valid ~ .input-group-append .input-group-text {
  border-color: #28a745;
}

.input-group .form-control.is-invalid:focus,
.register-card-body .input-group .form-control.is-invalid:focus {
  box-shadow: none;
}

.input-group .form-control.is-invalid ~ .input-group-append .input-group-text,
.register-card-body .input-group .form-control.is-invalid ~ .input-group-append .input-group-text {
  border-color: #dc3545;
}

.input-group .input-group-text,
.register-card-body .input-group .input-group-text {
  background-color: transparent;
  border-bottom-right-radius: 0.25rem;
  border-left: 0;
  border-top-right-radius: 0.25rem;
  color: #777;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-label {
  font-weight: normal;
}