
.mllp-result-block {
    margin-top: 15px;
}

.mllp-result {
    font-weight: bold;
}

.mllp-result.result-ok {
    color:green;
}

.mllp-result.result-error {
    color:red
}

code.raw-mllp-result {    
    font-size: inherit;
    color: #444444;
    background-color: #eee;
    padding: 5px;
    border: 1px solid #444444;
    border-radius: 3px;
    font-family: courier, monospace;
}

