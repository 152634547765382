.main-footer .main-logo {
    height: 30px;
}

.brand-image.img-circle.elevation-3 {
    background-color: #fff;
    padding: 4px;
}

.main-footer {
    min-height: 57px;
}

input.form-control.form-control-navbar:focus {
    box-shadow: unset!important;
}

.nav-sidebar .menu-open>.nav-link .right {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.text-before-fa-icon {
    margin-right: 5px;
}