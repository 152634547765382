.login-logo>img {
  width: 200px;
}

.login-logo,
.register-logo {
font-size: 2.1rem;
font-weight: 300;
margin-bottom: .9rem;
text-align: center;
}

.login-logo a,
.register-logo a {
color: #495057;
}

.login-page,
.register-page {
-ms-flex-align: center;
align-items: center;
background: #e9ecef;
display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column;
height: 100vh;
-ms-flex-pack: center;
justify-content: center;
}

.login-box,
.register-box {
width: 360px;
}

@media (max-width: 576px) {
.login-box,
.register-box {
  margin-top: 20px;
  width: 90%;
}
}

.login-card-body,
.register-card-body {
background: #ffffff;
border-top: 0;
color: #666;
padding: 20px;
}


.login-box-msg,
.register-box-msg {
margin: 0;
padding: 0 20px 20px;
text-align: center;
}

.social-auth-links {
margin: 10px 0;
}

.login-error-message {
color: red;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
font-size: 12px;
}

span[id^=not_signed_]:before {
content: 'Connexion avec un compte exolis';
padding-right:200px;
}

span[id^=connected]:before {
content: 'Connection effectuée';
padding-right:200px;
}